import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import publi1 from '../../../assets/imgs/publi1.gif';

class Publicidad1 extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Card>
                <Card.Body>
                    <Card.Title>
                        <span>Publicidad</span>
                        <i className="fa fa-bullhorn"></i>
                    </Card.Title>
                    <img src={ publi1 } alt=""/>
                </Card.Body>
            </Card>
         );
    }
}
 
export default Publicidad1;