import React, { Component } from 'react';
import Compartir from './compartir';
import Twitter from './twitter';
import Publicidad1 from './publicidad1';

class Sider extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="sider col-md-5 col-lg-3">
                <Publicidad1 />
                <Compartir />
                <Twitter />

            </div>
         );
    }
}
 
export default Sider;