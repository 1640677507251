import React, { Component, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Loading from '../components/main/loading';
import BItem from '../components/main/content/bitem';
import Localidades from '../components/main/content/localidades';
import p300x250 from '../assets/imgs/300x250.jpg';
import {Helmet} from "react-helmet";


class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            escorts : [],
            localidades: [],
            continua: true,
            cargando: true
         }
    }

    componentDidMount() {
        axios.get('//resource.guadalupehot.com/api/asociadas')
        .then(response => {
          let respuesta = response.data;
          this.setState({
            continua: respuesta.ok,
          })
          if(this.state.continua){
            this.setState({
                continua: respuesta.ok,
                escorts : respuesta.escorts.data,
                localidades: respuesta.localidades,
                cargando: false
            })
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    listarEscorts(){
        if(this.state.escorts.length > 0){
            
            return this.state.escorts.map((e, i)=>
                <div key={ i } className="col-lg-3 col-md-4 col-4 mb-3 nombres text-center"> 
                    <BItem slug={ e.slug } localidad={ e.localidad } apodo={ e.apodo } foto={ "https://resource.guadalupehot.com/portada_" + e.idasociada + "-" + e.idfoto + ".jpg" } /> 
                </div>
            );
        }
    }

    render() { 
        if(!this.state.continua){
            return <Redirect to="/error" />    
        }else{
            if(this.state.cargando){
                return(<Loading />);
            }else{
                return (
                    <Fragment>
                        <Helmet>
                            <title>Guadalupe HOT - Escorts en Chile, Acompa&ntilde;antes, Modelos, Bellas mujeres, Sexo</title>
                            <meta name="description" content="Las escorts mas bellas y ardientes del norte de Chile solo las podr&aacute;s encontrar en Guadalupe HOT, el mejor portal para adultos del internet." />
                            <link rel="canonical" href={ window.location.href } />
                            <meta name="generator" content="Guadalupehot 2.0.1" />

                            <meta prefix="og: http://ogp.me/ns#" property="og:title" content="Guadalupe HOT" />
                            <meta prefix="og: http://ogp.me/ns#" property="og:description" content="Las escorts mas bellas y ardientes del norte de Chile solo las podr&aacute;s encontrar en Guadalupe HOT, el mejor portal para adultos del internet." />
                            <meta prefix="og: http://ogp.me/ns#" property="og:url" content={ window.location.href } />
                            <meta prefix="og: http://ogp.me/ns#" property="og:image" content="http://www.guadalupehot.com/assets/imgs/fb_social.jpg" />
                            <meta prefix="og: http://ogp.me/ns#" property="og:site_name" content="Guadalupe HOT" />

                            <meta name="twitter:card" content="summary" />
                            <meta name="twitter:site" content="@GuadalupeHOTCL" />
                            <meta name="twitter:title" content="Guadalupe HOT" />
                            <meta name="twitter:description" content="Las escorts mas bellas y ardientes del norte de Chile solo las podr&aacute;s encontrar en Guadalupe HOT, el mejor portal para adultos del internet." />
                            <meta name="twitter:url" content={ window.location.href } />
                            <meta name="twitter:image:src" content="http://www.guadalupehot.com/assets/imgs/fb_social.jpg"></meta>
                        </Helmet>
                        <div className="content col-md-7 col-lg-9">
                            <div className="row">
                                <Localidades localidades={ this.state.localidades }/>
                            </div>
                            <div className="row">
                                { this.listarEscorts() }
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-6 text-center">
                                    <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                                </div>
                                <div className="col-md-12 col-lg-6 text-center col-md-hide">
                                    <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            }
        }
    }

/*

                <div className="row">
                    <div className="col-md-12 col-lg-6 text-center">
                        <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                    </div>
                    <div className="col-md-12 col-lg-6 text-center col-md-hide">
                        <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                    </div>
                </div>

*/                

}
 
export default Inicio;