import React, { Component, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Loading from '../components/main/loading';
import Carousels from '../components/main/content/carousel';
import p300x250 from '../assets/imgs/300x250.jpg';
import p468x60 from '../assets/imgs/468x60.jpg';
import {Helmet} from "react-helmet";

class Ficha extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            info : [],
            lugares: [],
            servicios: [],
            fotos: [],
            continua: true,
            cargando: true,
            verfotos: false
        }
        this.regresar = this.regresar.bind(this);
        this.tarifa = this.tarifa.bind(this);
        this.collapseServicios = this.collapseServicios.bind(this);
    }

    componentDidMount() {
        axios.get('//resource.guadalupehot.com/api/asociada/'+this.props.match.params.escort)
        .then(response => {
          let respuesta = response.data;
          this.setState({
            continua: respuesta.ok,
          })
          if(this.state.continua){
            this.setState({
                info : respuesta.info,  
                lugares: respuesta.lugares,
                servicios: respuesta.servicios,
                fotos: respuesta.fotos,
                cargando: false
            })
          }
          
        })
        .catch(error => {
          console.log(error);
        });
    }

    clearHTML(origen) {
        let string = origen;
        string = string.replace('&aacute;', 'á');
        string = string.replace('&Aacute;', 'Á');
        string = string.replace('&eacute;', 'é');
        string = string.replace('&Eacute;', 'É');
        string = string.replace('&iacute;', 'í');
        string = string.replace('&Iacute;', 'Í');
        string = string.replace('&oacute;', 'ó');
        string = string.replace('&Oacute;', 'Ó');
        string = string.replace('&uacute;', 'ú');
        string = string.replace('&Uacute;', 'Ú');
        return string;
      };
  
    render() { 
        if(!this.state.continua){
            return <Redirect to="/error" />    
        }else{
            if(this.state.cargando){
                return(<Loading />);
            }else{
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                let info = this.state.info;
                let servicios = this.state.servicios;
                let lugares = this.state.lugares
                let monto = this.tarifa(info.tarifa);
                const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
                return (
                    <Fragment>
                        <Helmet>
                            <title>Escort › { this.clearHTML(info.apodo) } | Guadalupe HOT - Escorts en Chile, Acompañantes, Modelos, Bellas mujeres, Sexo</title>
                            <meta name="description" content={ info.texto } />
                            <link rel="canonical" href={ window.location.href } />
                            <meta name="generator" content="Guadalupehot Mobil 2.0" />

                            <meta prefix="og: http://ogp.me/ns#" property="og:title" content="Guadalupe HOT" />
                            <meta prefix="og: http://ogp.me/ns#" property="og:description" content={ info.texto } />
                            <meta prefix="og: http://ogp.me/ns#" property="og:url" content={ window.location.href } />
                            <meta prefix="og: http://ogp.me/ns#" property="og:image" content={ "https://resource.guadalupehot.com/portada_" + info.idasociada + "-" + info.idfoto + ".jpg" } />
                            <meta prefix="og: http://ogp.me/ns#" property="og:site_name" content="Guadalupe HOT" />

                            <meta name="twitter:card" content="summary" />
                            <meta name="twitter:site" content="@GuadalupeHOTCL" />
                            <meta name="twitter:title" content="Guadalupe HOT" />
                            <meta name="twitter:description" content={ info.texto } />
                            <meta name="twitter:url" content={ window.location.href } />
                            <meta name="twitter:image:src" content={ "https://resource.guadalupehot.com/portada_" + info.idasociada + "-" + info.idfoto + ".jpg" }></meta>
                        </Helmet>
                    
                        <div className="content col-md-7 col-lg-9">
                        <div className="ficha">
                            <div className="top">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-6">
                                        <div className="pull-left btnregresa" onClick={ this.regresar }>
                                            <svg viewBox="0 0 24 24" className="regresar">
                                                <g>
                                                    <path d="M20 11H7.414l4.293-4.293c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0l-6 6c-.39.39-.39 1.023 0 1.414l6 6c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L7.414 13H20c.553 0 1-.447 1-1s-.447-1-1-1z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="pull-left nombre"> 
                                            { this.clearHTML(info.apodo) } 
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-6 text-center">
                                        <div className="celular">
                                            <i className="fa fa-mobile celular"></i> <span>{ info.celular }</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-hide">
                                        <div className="pull-right localidad">
                                            <i className="fa fa-map-marker"></i> { info.localidad }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contenido">
                                <div className="row">
                                    <div className="col-12 detalle col-lg-hide">
                                        <div className="pull-right localidad">
                                            <i className="fa fa-map-marker"></i> { info.Localidad }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 detalle">
                                        <div className="row">
                                            <div className="col-sm-12 text-center">
                                                <b>DETALLES</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.edad }</span>Edad</div>
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.pais }</span>País</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.medidas }</span>Medidas</div>
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.estatura } mts.</span>Estatura</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.peso } Kg.</span>Peso</div>
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.piel }</span>Piel</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.cabello }</span>Cabello</div>
                                            <div className="col-sm-6 mt-2"><span className="describe">{ info.ojos }</span>Ojos</div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-12 text-right">Tarifa</div>
                                            <div className="col-sm-12 text-right describe tarifa">{ "$ " + monto }</div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-12">Atención</div>
                                            <div className="col-sm-12 describe">{ renderHTML(lugares) }</div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-12">Ubicación</div>
                                            <div className="col-sm-12 describe">{ renderHTML(info.ubicacion) }</div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-12">Servicios</div>
                                            <div className="col-sm-12 describe">
                                                { renderHTML(info.defservicio) } 
                                                <div className="mas" onClick={ this.collapseServicios }>detalle</div>
                                                <br />
                                                <div className="collapse" ref="servicios">
                                                    { renderHTML(servicios) }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-12">Horario</div>
                                            <div className="col-sm-12 describe">{ renderHTML(info.horario) }</div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-sm-12 describe">{ renderHTML(info.texto) }</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 xmedia">
                                        <div className="row">
                                            <div className="col-12 text-center mt-2 mb-4">
                                                <b>FOTOS</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Carousels asociada={ info.idasociada } fotos={ this.state.fotos } />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pubs">
                                <div className="row mt-4">
                                    <div className="col-lg-6 col-md-12 text-center">
                                        <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                                    </div>
                                    <div className="col-lg-6 col-md-12 text-center col-md-hide">
                                        <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                    </Fragment>
                );
            }
        }
    }

/*
        <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
        <iframe src="/tr468x60.html" frameBorder="0" height="65" scrolling="no"></iframe>
*/        


    regresar = (e) => {
        if (this.props.history.goBack()) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    }

    tarifa = (monto) => {
        let lng = 0, pri = 0, seg = 0, cantidad = '';
        if(monto){
            cantidad = monto.toString();
            lng = cantidad.length;
            if(lng > 3 && lng < 6){
                pri = lng - 3;
                seg = cantidad.substring(pri, lng)
                return cantidad.substring(0,pri) + '.' + seg;
            }
            return '';
        }
    }

    collapseServicios = () => {
        this.refs.servicios.classList.toggle('show');
    }
}
 
export default Ficha;