import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class Localidades extends Component {
    constructor(props) {
        super(props);
        this.localidad = this.localidad.bind(this);
    }

    listarLocalidades(){

        if(this.props.localidades){
            let { data } = this.props.localidades;
            return data.map((l, i)=>
                <Link key={ i } to={ "/localidad/" + l.slug }>{ l.nombre }</Link>
            );
        }
    }
    render() { 
        let { total } = this.props.localidades;

        if( total > 1 ){
                return ( 
                    <div className="content col-12 mb-4">
                        <div className="top pt-3">
                            <div className="row">
                                <div className="col-12 pb-2">
                                    <div className="pull-right">
                                        Localidad:
                                        <Link className="selected" to="/">Todos</Link>
                                        { this.listarLocalidades() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }else{
            
            return(
                <Fragment></Fragment>
            );
        }
    }

    localidad = (e) => {
        console.log(e)
    }
}
 
export default Localidades;