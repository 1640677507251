import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { TwitterShareButton } from 'react-twitter-embed';


class Compartir extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Card>
                <Card.Body>
                    <Card.Title>
                        <span>Compartir</span>
                        <i className="fa fa-share-alt"></i>
                    </Card.Title>
                    <TwitterShareButton
                        url='https://www.guadalupehot.com'
                        options={{ text: 'Las escorts mas bellas y ardientes del norte de Chile solo las podr&aacute;s encontrar en Guadalupe HOT, el mejor portal para adultos del internet.', via: 'guadalupehotcl' }}
                        lang="es"
                    />
                </Card.Body>
            </Card>

         );
    }
}
 
export default Compartir;