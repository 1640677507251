import React, { Component } from 'react';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
                <div className="content col-md-7 col-lg-9">
                    <div className="loading"></div>
                    <div className="tloading">Cargando...</div>
                </div>

         );
    }
}
 
export default Loading;