import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import logoTop from '../../assets/imgs/logo-top.png';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <header>
                <Container>
                    <div className="logo pull-left">
                        <Link to="/" className="logotop">
                            <img src={logoTop} alt="Guadalupe Hot"/>
                        </Link>
                    </div>
                    <div className="navtop pull-right">
                        <Link to="/">Inicio</Link>
                        <Link to="/publicate">Publícate</Link>
                        <Link to="/contactanos">Contáctanos</Link>
                    </div>
                </Container>
            </header>
        );
    }
}
 
export default Header;