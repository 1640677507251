import React, { Component, Fragment } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import Loading from '../components/main/loading';
import BItem from '../components/main/content/bitem';
import p300x250 from '../assets/imgs/300x250.jpg';
import {Helmet} from "react-helmet";


class Localidad extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            escorts : [],
            localidades: [],
            continua: true,
            cargando: true
         }
         this.regresar = this.regresar.bind(this);
    }

    componentDidMount() {
        axios.get('//resource.guadalupehot.com/api/asociadas/'+this.props.match.params.localidad)
        .then(response => {
          let respuesta = response.data;
          this.setState({
            continua: respuesta.ok,
          })
          if(this.state.continua){
            this.setState({
                continua: respuesta.ok,
                escorts : respuesta.escorts.data,
                localidades: respuesta.localidades.data,
                cargando: false
            })
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    

    listarEscorts(){
        if(this.state.escorts.length > 0){
            
            return this.state.escorts.map((e, i)=>
                <div key={ i } className="col-lg-3 col-md-4 col-4 mb-3 nombres text-center"> 
                    <BItem slug={ e.slug } localidad={ e.localidad } apodo={ e.apodo } foto={ "https://resource.guadalupehot.com/portada_" + e.idasociada + "-" + e.idfoto + ".jpg" } /> 
                </div>
            );
        }
    }

    nombreLocalidades(){
        if(this.state.localidades.length > 0){
          for (let index = 0; index < this.state.localidades.length; index++) {
            const element = this.state.localidades[index];
            if(element.slug === this.props.match.params.localidad)
              return (element.nombre);
          }
        }
    }
    
    clearHTML(origen) {
        let string = origen;
        string = string.replace('&aacute;', 'á');
        string = string.replace('&Aacute;', 'Á');
        string = string.replace('&eacute;', 'é');
        string = string.replace('&Eacute;', 'É');
        string = string.replace('&iacute;', 'í');
        string = string.replace('&Iacute;', 'Í');
        string = string.replace('&oacute;', 'ó');
        string = string.replace('&Oacute;', 'Ó');
        string = string.replace('&uacute;', 'ú');
        string = string.replace('&Uacute;', 'Ú');
        return string;
      };
  

    render() { 
        //console.log(this.props.history);
        if(!this.state.continua){
            return <Redirect to="/error" />    
        }else{
            if(this.state.cargando){
                return(<Loading />);
            }else{
                return (
                    <Fragment>
                        <Helmet>
                            <title>Escorts en { this.clearHTML(this.nombreLocalidades()) } | Guadalupe HOT - Escorts en Chile, Acompañantes, Modelos, Bellas mujeres, Sexo</title>
                            <meta name="description" content={ "Las escorts mas bellas y ardientes del norte de " + this.clearHTML(this.nombreLocalidades()) + " solo las podr&aacute;s encontrar en Guadalupe HOT, el mejor portal para adultos del internet." } />
                            <link rel="canonical" href={ window.location.href } />
                            <meta name="generator" content="Guadalupehot Mobil 2.0" />

                            <meta prefix="og: http://ogp.me/ns#" property="og:title" content="Guadalupe HOT" />
                            <meta prefix="og: http://ogp.me/ns#" property="og:description" content={ "Las escorts mas bellas y ardientes del norte de " + this.clearHTML(this.nombreLocalidades()) + " solo las podr&aacute;s encontrar en Guadalupe HOT, el mejor portal para adultos del internet." } />
                            <meta prefix="og: http://ogp.me/ns#" property="og:url" content={ window.location.href } />
                            <meta prefix="og: http://ogp.me/ns#" property="og:image" content="http://www.guadalupehot.com/assets/imgs/fb_social.jpg" />
                            <meta prefix="og: http://ogp.me/ns#" property="og:site_name" content="Guadalupe HOT" />

                            <meta name="twitter:card" content="summary" />
                            <meta name="twitter:site" content="@GuadalupeHOTCL" />
                            <meta name="twitter:title" content="Guadalupe HOT" />
                            <meta name="twitter:description" content={ "Las escorts mas bellas y ardientes del norte de " + this.clearHTML(this.nombreLocalidades()) + " solo las podr&aacute;s encontrar en Guadalupe HOT, el mejor portal para adultos del internet." } />
                            <meta name="twitter:url" content={ window.location.href } />
                            <meta name="twitter:image:src" content="http://www.guadalupehot.com/assets/imgs/fb_social.jpg"></meta>
                        </Helmet>
                        <div className="content col-md-7 col-lg-9">
                            <div className="row">
                                <div className="content col-12 mb-4">
                                    <div className="top">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="pull-left btnregresa" onClick={ this.regresar }>
                                                    <svg viewBox="0 0 24 24" className="regresar">
                                                        <g>
                                                            <path d="M20 11H7.414l4.293-4.293c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0l-6 6c-.39.39-.39 1.023 0 1.414l6 6c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L7.414 13H20c.553 0 1-.447 1-1s-.447-1-1-1z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="pull-right nombre">
                                                    { 'Escorts en ' + this.nombreLocalidades() }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                { this.listarEscorts() }
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-6 text-center">
                                    <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                                </div>
                                <div className="col-md-12 col-lg-6 text-center col-md-hide">
                                    <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            }
        }
    }

/*

                <div className="row">
                    <div className="col-md-12 col-lg-6 text-center">
                        <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                    </div>
                    <div className="col-md-12 col-lg-6 text-center col-md-hide">
                        <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                    </div>
                </div>

*/                

    regresar = (e) => {
        if (this.props.history.goBack()) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    }

}
 
export default Localidad;