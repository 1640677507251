import React, { Component, Fragment } from 'react';
import { Button, Label } from "reactstrap";
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
    AvFeedback,
  } from "availity-reactstrap-validation";
import axios from 'axios';
import p160x600 from '../assets/imgs/160x600.jpg';
import p300x250 from '../assets/imgs/300x250.jpg';



class Publicate extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            file: null,
            enviar: true,
            lblenviar: 'Enviar',
            msger: '',
            err: 'oculta',
            suc: 'oculta'
         }
        this.regresar = this.regresar.bind(this);
        this.enviar = this.enviar.bind(this);
        this.noenviar = this.noenviar.bind(this);
        this.handleFile = this.handleFile.bind(this);
    }

    render() { 
        return ( 
            <Fragment>
                <div className="content col-md-7 col-lg-9">
                    <div className="top">
                        <div className="row">
                            <div className="col-12">
                                <div className="pull-left btnregresa" onClick={ this.regresar }>
                                    <svg viewBox="0 0 24 24" className="regresar">
                                        <g>
                                            <path d="M20 11H7.414l4.293-4.293c.39-.39.39-1.023 0-1.414s-1.023-.39-1.414 0l-6 6c-.39.39-.39 1.023 0 1.414l6 6c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023 0-1.414L7.414 13H20c.553 0 1-.447 1-1s-.447-1-1-1z"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="pull-right nombre">
                                    PUBLÍCATE
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contenido">
                        <div className="row">
                            <div className="col-lg-9 detalle">
                                <div className="row">
                                    <div className="col-12 mb-5">
                                        Si desea publicarse en Guadalupe HOT, por favor ingrese los datos solicitados a continuación para poder comunicarnos con usted posteriormente.
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className={ "alert alert-success " + this.state.suc } role="alert">
                                            Gracias por enviar su información a <strong>GUADALUPE HOT</strong>, responderemos su mensaje lo mas breve posible.
                                        </div>
                                        <div className={ "alert alert-danger " + this.state.err } role="alert">
                                            { this.state.msger }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <AvForm className="av-tooltip tooltip-label-right" onValidSubmit={this.enviar} onInvalidSubmit={this.noenviar} ref={c => (this.form = c)}>
                                            <AvGroup className="error-l-75">
                                                <Label>Nombre *</Label>
                                                <AvInput name="nombre" required />
                                                <AvFeedback>Nombre requerido!</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="error-t-negative">
                                                <Label>Correo *</Label>
                                                <AvField
                                                    name="correo"
                                                    type="email"
                                                    validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Correo requerido!"
                                                    },
                                                    email: {
                                                        value: true,
                                                        errorMessage: "Por favor ingrese un correo válido"
                                                    }
                                                    }}                                       
                                                />
                                            </AvGroup>
                                            <AvGroup className="error-l-50">
                                                <Label>Foto *</Label>
                                                <AvInput
                                                    name="foto"
                                                    type="file"
                                                    className="file-personalizado form-control"
                                                    validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Foto requerida!"
                                                    }
                                                    }}
                                                    onChange={(e)=>this.handleFile(e)}
                                                    accept="image/jpeg"                                       
                                                />
                                                <AvFeedback>Foto requerida!</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="error-l-100">
                                                <Label>Comentario *</Label>
                                                <AvInput type="textarea" name="comentario" id="comentario" placeholder="Incluya la edad, teléfono y ciudad." required />
                                                <AvFeedback>Comentario requerido!</AvFeedback>
                                            </AvGroup>
                                            <Button color="danger" disabled={!this.state.enviar}>{this.state.lblenviar}</Button>
                                        </AvForm> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-hide text-center pt-3">
                                <iframe src="/tr160x600.html" frameBorder="0" height="605" scrolling="no"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="pubs">
                        <div className="row mt-4">
                            <div className="col-lg-6 col-md-12 text-center">
                                <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                            </div>
                            <div className="col-lg-6 col-md-12 text-center col-md-hide">
                                <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment> 
            );
    }
/*
    <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
    <iframe src="/tr160x600.html" frameBorder="0" height="605" scrolling="no"></iframe>
*/
    regresar = (e) => {
        if (this.props.history.goBack()) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    }



    enviar(event, values) {
         
        this.setState({
            msger: '',
            err: 'oculta',
            suc: 'oculta'
        });

        let file = this.state.file;
        
        var formData = new FormData();

        formData.append('archivo', file);
        formData.append('nombre', values.nombre);
        formData.append('correo', values.correo);
        formData.append('comentario', values.comentario);
        
        this.setState({enviar: false, lblenviar: 'Enviando...' });

        
        axios.post('https://resource.guadalupehot.com/api/publicate', formData)
        .then(response => {
            let respuesta = response.data;
            if(respuesta.ok){
                this.setState({
                    suc: 'show'
                })
                this.form && this.form.reset();
            }else{
                this.setState({
                    msger: respuesta.message,
                    err: 'show'
                })
            }                
        })
        .catch(error => {
            console.log(error);
        });
    }

    noenviar(event, errors, values) {
        this.setState({
            msger: '',
            err: 'oculta',
            suc: 'oculta'
        });
        if (errors.length) {
            this.setState({
                msger: 'Se encontraron errores, por favor revise',
                err: 'show'
            });
            return false;
        }
        let file = this.state.file;

        if(file == null || file == undefined){
            this.setState({
                msger: 'Por favor seleccione una foto',
                err: 'show'
            })
            return false;
        }
        
    }

    handleFile(e){
        let file = e.target.files[0];
        this.setState({
            file: file
        });
    }
    
}
 
export default Publicate;