import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { TwitterTimelineEmbed } from 'react-twitter-embed';


class Twitter extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Card>
                <Card.Body>
                    <Card.Title>
                        <span>Twitter</span>
                        <i className="fa fa-twitter"></i>
                    </Card.Title>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="guadalupehotcl"
                        options={{height: 500, lang: 'es'}}
                        theme="dark"
                        charset="utf-8"
                        link-color="#E95F28"
                        
                    />
                </Card.Body>
            </Card>

         );
    }
}
 
export default Twitter;