import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import nf from '../assets/imgs/404.jpg';

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="content col-md-7 col-lg-9">
                <div className="contenido">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 mb-5 mt-5 text-center">
                                    <img src={ nf } alt=""/> <br/><br/>
                                    <h4>Vaya, al parecer lo que está buscando ya no existe.</h4>
                                    <br/>
                                    <br/>
                                    <Link to="/" >Ir al Inicio</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pubs">
                    <div className="row mt-4">
                        <div className="col-lg-6 col-md-12 text-center">
                            <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                        </div>
                        <div className="col-lg-6 col-md-12 text-center col-md-hide">
                            <iframe src="/tr300x250.html" frameBorder="0" height="255" scrolling="no"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Error;