import React, { Component } from 'react';
import { Link } from "react-router-dom";

class BItem extends Component {
    constructor(props) {
        super(props);
        this.state = {  }

    }

    render() { 
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        let { slug, foto, apodo, localidad } = this.props;
        return ( 
            <div className="cntItem">
                <Link to={ "/escort/" + slug }>
                    <img src={ foto } className="pic" alt={ apodo } /><br/>
                    <div className="etqLocalidad"><i className="fa fa-map-marker"></i> { localidad }</div>
                    <div className="etqNombre">{ renderHTML(apodo) }</div>
                </Link>
            </div>
         );
    }
}
 
export default BItem;