import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'

class Carousels extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    listarFotos(){
        let { fotos, asociada } = this.props;
        if( fotos.length > 0){
            
            return fotos.map((f, i)=>
                <Carousel.Item key={ i }> 
                    <img src={ 'https://resource.guadalupehot.com/foto_' + asociada + '-' + f.idfoto + '.jpg' } alt=""/>
                    <div className="protector"> </div>
                </Carousel.Item>
            );
        }
    }
    render() { 
        return ( 
            <Carousel interval="5000" indicators="true">
                { this.listarFotos() }
            </Carousel>
         );
    }
}
 
export default Carousels;