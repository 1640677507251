import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Header from './components/header/header';
import Sider from './components/main/sider';
import Footer from './components/footer/footer';
import Inicio from './views/inicio';
import Localidad from './views/localidad';
import Ficha from './views/ficha';
import Publicate from './views/publicate';
import Contactanos from './views/contactanos';
import Error from './views/error';

function App() {
  return (
    <Fragment>
      <Router>
        <Header />
        <div className="container main">
          <div className="row">
            <Sider />
            <Switch>
              <Route path="/error" exact component={ Error } />
              <Route path="/localidad/:localidad" exact component={Localidad} />
              <Route path="/escort/:escort" exact component={Ficha} />
              <Route path="/contactanos" component={Contactanos} />
              <Route path="/publicate" component={Publicate} />
              <Route path="/" exact component={ Inicio } />
              <Redirect to="/error" />
            </Switch>
          </div>
        </div>
        <Footer />
      </Router>
    </Fragment>
  );
}

export default App;
