import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <footer>
                <Container>
                    <div className="pie">
                        <div className="container">
                            <div className="row">

                                <div className="pull-left col-sm-12 col-md-6 izquierda">
                                    <p>Las más bellas y ardientes Escorts de Chile sólo las encontrarás en <strong>GUADALUPE HOT</strong>.<br/><br/>
                                    Este sitio está dirigido para personas <b>mayores de 18 años</b>.<br/><br/>
                                    <strong>GUADALUPE HOT</strong> <b>no es una agencia</b> de escorts en Chile. Su actividad se limita a la publicidad en la página de 
                                    Internet que lleva su nombre.<br/><br/>
                                    Las relaciones que pudieran establecer las escorts en Chile que publican sus fotos y avisos en este sitio, con terceros sean 
                                    personas físicas o jurídicas, corren por su exclusiva cuenta y responsabilidad, no teniendo <strong>GuadalupeHot.Com</strong> participación
                                    , interés o responsabilidad para las mismas.</p>
                                </div>
                                <div className="pull-right col-sm-12 col-md-6 derecha">
                                    <div className="columnas">
                                        <strong>Informacion y contacto</strong><br/>
                                        <ul>
                                            <li><Link to="/contactanos">Contáctanos</Link></li>
                                            <li><Link to="/publicate">Publícate</Link></li>
                                            <li><a href="https://twitter.com/GuadalupeHOTCL" target="twtt">Nuestro Twitter</a></li>
                                        </ul>

                                        <ul className="certificados">
                                            <li ><a className="lg-asacp" href="http://www.asacp.org/" target="_blank">Asacp</a></li>
                                            <li><a className="lg-rta" href="http://rtalabel.org/?content=validate&rating=RTA-5042-1996-1400-1577-RTA" target="_blank">Restricted To Adults</a></li>
                                        </ul>
                                    </div>
                                    <div className="copy"><Link to="/"><b>GUADALUPE HOT</b></Link> © Todos los derechos reservados 2013 - 2020</div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Container>
            </footer>
         );
    }
}
 
export default Footer;